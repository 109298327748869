import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const CardMaskInput = ({ inputRef, ...props }) => (
  <MaskedInput
    guide
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
  />
);

CardMaskInput.defaultProps = {
  inputRef: () => null,
};

CardMaskInput.propTypes = {
  inputRef: PropTypes.func,
};

export default CardMaskInput;
