export const typography = {
  allVariants: {
    fontFamily: 'Roboto',
  },
  h1: {
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: 1.8,
  },
  h2: {
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: 1.2,
  },
  h3: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.125,
  },
  h4: {
    fontWeight: 600,
    fontSize: '0.9rem',
    lineHeight: 1.25,
  },
  h5: {
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: 1.25,
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.7rem',
    lineHeight: 1.25,
  },
  body: {
    fontWeight: 200,
    fontSize: '0.75rem',
    lineHeight: 1.2,
  },
  caption: {
    fontWeight: 200,
    fontSize: '0.7rem',
    lineHeight: 1.3,
  },
  overline: {
    fontWeight: 100,
    fontSize: '0.65rem',
    lineHeight: 1.1,
  },
  title: {
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: 1.25,
  },
};
