import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useController } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const sanitizeProps = ({ onInputChange, fullWidth, ...props }) => props;

const useStyles = makeStyles(({ spacing }) => ({
  root: { paddingLeft: spacing(1) },
}));

const RadioGroupField = ({
  row,
  name,
  form,
  rules,
  label,
  control,
  options = [],
  fullWidth,
  defaultValue = false,
  ...rest
}) => {
  const classes = useStyles();
  const props = sanitizeProps(rest);
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const onChange = (e) => {
    field.onChange(e.target.value);
  };

  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={field.value}
        onChange={onChange}
        {...rest}
        {...props}
        classes={classes}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: row ? 'row' : 'column',
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            control={<Radio color="primary" />}
            label={option.label}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupField.propTypes = {
  row: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }),
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

export default RadioGroupField;
