import React from 'react';
import get from 'lodash/get';
import { View, StyleSheet } from '@react-pdf/renderer';

import { generalInfo } from '../constants/dataModels';
import Field from './Field';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
  },
  item: { width: '50%', padding: 4 },
});

const GeneralInfo = ({ data = {} }) => (
  <View style={styles.container}>
    {generalInfo.map((item) => {
      const value = get(data, item.name);

      return (
        <View
          key={item.name}
          style={{ ...styles.item, width: item.width || styles.item.width }}
        >
          <Field
            value={item.getRenderValue ? item.getRenderValue(value) : value}
            label={item.label}
            type={item.field}
          />
        </View>
      );
    })}
  </View>
);

export default GeneralInfo;
