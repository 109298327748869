import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';

import { selectColumns } from '../constants/contactSelect';

const useStyles = makeStyles({
  cell: {
    '&::before': {
      top: '25%',
      left: 1,
      width: 3,
      height: '50%',
      position: 'absolute',
      content: '" "',
      backgroundColor: '#bdbdbd80',
    },
  },
});

const SelectContactTable = ({ children, ...props }) => {
  const styles = useStyles();
  return (
    <Paper {...props}>
      <Table
        size="small"
        style={{ display: 'flex', flexDirection: 'column', flex: 100 }}
      >
        <TableHead
          style={{
            zIndex: 1,
            flex: 100,
          }}
        >
          <TableRow style={{ flex: 100, display: 'flex' }}>
            {selectColumns.map((column) => (
              <TableCell
                key={column.Header}
                style={{
                  flex: column.width || 100 / selectColumns.length,
                  position: 'relative',
                }}
              >
                <strong className={styles.cell}>{column.Header}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ flex: 100 }}>{children}</TableBody>
      </Table>
    </Paper>
  );
};

export default SelectContactTable;
