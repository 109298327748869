import React from 'react';
import get from 'lodash/get';
import { View, StyleSheet } from '@react-pdf/renderer';

import Field from './Field';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
  },
  item: { width: '100%', padding: 4 },
  itemRadioContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
});

const InfoContainers = ({ data, style, model = [], originalModel = [] }) => (
  <View style={{ ...styles.container, ...style }}>
    {model.map((item) => {
      const value = get(data, item.name);
      const originalItem = originalModel.find((x) => x.name === item.name);

      if (originalItem?.options && originalItem?.field === 'checkbox') {
        const width = originalItem.twoCols ? '50%' : '100%';
        const midOfCols = Math.ceil(originalItem.options.length / 2);

        const firstCol = originalItem?.options.slice(0, midOfCols);
        const secondCol = originalItem?.options.slice(midOfCols);

        return (
          <View
            key={item.name}
            style={{
              ...styles.itemRadioContainer,
              width: item.width || styles.itemRadioContainer.width,
            }}
          >
            <View style={{ width }}>
              {firstCol.map((option) => (
                <Field
                  key={option.label}
                  value={get(data, [item.name, option.value])}
                  label={option.label}
                  type="checkbox"
                />
              ))}
            </View>

            <View style={{ width }}>
              {secondCol.map((option) => (
                <Field
                  key={option.label}
                  value={get(data, [item.name, option.value])}
                  label={option.label}
                  type="checkbox"
                />
              ))}
            </View>
          </View>
        );
      }

      if (originalItem?.options && originalItem?.field === 'radio') {
        const width = `${100 / originalItem.options.length}%`;

        return (
          <View
            key={item.name}
            style={{
              paddingBottom: 2,
              paddingHorizontal: 5,
              ...styles.itemRadioContainer,
              width: item.width || styles.itemRadioContainer.width,
            }}
          >
            <View
              key={item.name}
              style={{ flexDirection: 'row', justifyContent: 'space-around' }}
            >
              {originalItem.options.map((option) => (
                <Field
                  key={option.label}
                  value={get(data, item.name) === option.value}
                  label={option.label}
                  type="radio"
                  style={{
                    width,
                    justifyContent: 'center',
                    flexDirection: 'row-reverse',
                  }}
                  labelStyle={{ paddingHorizontal: 4, flexGrow: 0 }}
                />
              ))}
            </View>
          </View>
        );
      }

      return (
        <View
          key={item.name}
          style={{ ...styles.item, width: item.width || styles.item.width }}
        >
          <Field
            value={
              originalItem?.getRenderValue
                ? originalItem.getRenderValue(value)
                : value
            }
            label={item.label || originalItem?.label}
            type={item.field || originalItem?.field}
          />
        </View>
      );
    })}
  </View>
);

export default InfoContainers;
