import * as Yup from 'yup';

import * as schemes from 'common/utils/validationSchemas';
import validationMessages from 'common/constants/validationErrors';

export default Yup.object().shape({
  firstName: Yup.string().required(
    validationMessages.account.firstNameRequired
  ),
  lastName: Yup.string().required(validationMessages.account.lastNameRequired),
  phone: schemes.phone.required(validationMessages.phone.required),
  email: schemes.email,
});
