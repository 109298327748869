import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';

import resources from 'common/constants/resources';
import Autocomplete from 'common/components/formFields/Autocomplete';

import Tooltip from '@material-ui/core/Tooltip';
import { selectColumns } from '../constants/contactSelect';
import SelectContactTable from './SelectContactTable';

const searchByFields = ['phone', 'email', 'firstName', 'lastName', 'fullName'];

const filterOptions = (options, { inputValue }) => {
  const search = inputValue.toLowerCase().trim();

  return options.filter((option) =>
    searchByFields.some((field) => String(get(option, field)).includes(search))
  );
};

const SelectExistingContact = ({ setContact }) => {
  const { formState, control, watch } = useForm();

  const contact = watch('contact');

  useEffect(() => {
    setContact(contact || {});
  }, [contact, setContact]);

  return (
    <DialogContent>
      <Autocomplete
        label="Choose existing contact"
        size="small"
        disableClearable={false}
        control={control}
        form={formState}
        name="contact"
        optionLabel="fullName"
        optionValueName="id"
        inputProps={{ style: { whiteSpace: 'pre' } }}
        resource={resources.contact.list}
        PaperComponent={SelectContactTable}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option
            ? [
                option.firstName,
                option.lastName,
                option.phone,
                option.email,
                [option.assignedPhysiciansNames]
                  .flat()
                  .filter(Boolean)
                  .join(', '),
              ]
                .filter(Boolean)
                // extra spaces works only with whiteSpace: 'pre'
                .join('   |   ')
            : '';
        }}
        renderOption={(original) => {
          return (
            <TableRow style={{ flex: '1 0 auto', display: 'flex' }}>
              {selectColumns.map((column) => {
                const value = get(original, column.accessor);

                const currentValue = column.valueGetter
                  ? column.valueGetter({ row: { original }, value })
                  : value;

                return (
                  <TableCell
                    key={column.accessor}
                    style={{
                      boxSizing: 'border-box',
                      maxWidth: '100%',
                      width: `${column.width || 100 / selectColumns.length}px`,
                      padding: '8px 16px',
                      paddingTop: 0,
                      flex: `${
                        column.width || 100 / selectColumns.length
                      } 0 auto`,
                    }}
                  >
                    <Tooltip title={currentValue}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {currentValue}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        }}
      />
    </DialogContent>
  );
};

export default SelectExistingContact;
