import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

import PDFTypography from 'common/components/PDFTypography';

const getStyles = (position = 'left') => {
  const isLeft = position === 'left';

  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: isLeft ? 'row' : 'column',
      border: isLeft ? '1px solid #bdc0c0' : '2px solid black',
    },
    highlight: {
      display: 'flex',
      padding: isLeft ? 4 : 1,
      width: isLeft ? 20 : '100%',
      height: isLeft ? '100%' : 20,
      flexDirection: 'row',
      backgroundColor: '#e6e9ef',
      justifyContent: 'center',
      alignItems: 'center',
      ...(isLeft
        ? { borderRight: '1px solid #bdc0c0' }
        : { borderBottom: '1px solid black' }),
    },
    highlightText: {
      fontSize: 12,
      color: '#000000',
      ...(isLeft
        ? {
            minWidth: 100,
            width: '10000%',
            textAlign: 'center',
            transform: 'rotate(-90deg)',
          }
        : {
            height: '100%',
            width: 'auto',
          }),
    },
    content: {
      padding: 8,
      paddingVertical: 4,
    },
  });
};

const Section = ({
  style = {},
  children,
  highlight = true,
  highlightTitle,
  highlightSubtitle,
  highlightPosition,
  ...props
}) => {
  const innerStyles = getStyles(highlightPosition);

  return (
    <View {...props} style={{ ...style, ...innerStyles.container }}>
      {highlight && (
        <View style={innerStyles.highlight}>
          {!!highlightTitle && (
            <PDFTypography styles={innerStyles.highlightText}>
              {highlightTitle}
            </PDFTypography>
          )}

          {!!highlightSubtitle && (
            <PDFTypography styles={{ fontSize: 8, paddingLeft: 4 }}>
              {highlightSubtitle}
            </PDFTypography>
          )}
        </View>
      )}

      <View style={innerStyles.content}>{children}</View>
    </View>
  );
};

Section.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  highlight: PropTypes.bool,
  highlightTitle: PropTypes.string,
  highlightSubtitle: PropTypes.string,
  highlightPosition: PropTypes.oneOf(['left', 'top']),
};

export default Section;
