import { recursiveKey, getUrlEnum } from 'common/utils/helpers/url';

export const stack = {
  public: {
    login: {
      url: '/sign-in',
      title: 'Sign In',
    },
    confirmationCode: {
      url: '/confirmation-code',
      title: 'Login',
    },
    forgotPassword: {
      url: '/forgot-password',
      title: 'Forgot Password',
    },
    resetPassword: {
      url: '/reset-password/:token',
      title: 'Reset Password',
    },
    notFound: {
      url: `/not-found`,
      title: 'Page not found',
    },
  },
  private: {
    index: {
      url: '/',
      title: 'Home',
    },
    dashboard: {
      url: '/dashboard',
      title: 'Dashboard',
    },
    userProfile: {
      url: '/user/:id',
      title: 'User Profile',
    },
    procedures: {
      list: {
        url: `/procedures`,
        title: 'All Procedures',
      },
      create: {
        url: `/procedures/create`,
        title: 'Create Procedure',
      },
      edit: {
        url: `/procedures/:id/edit`,
        title: 'Edit Procedure',
      },
      firstDayReports: {
        url: `/procedures/first-day-reports`,
        title: 'First Day Reports',
      },
      dailyReports: {
        url: `/procedures/daily-reports`,
        title: 'Daily Reports',
      },
      lastDayReports: {
        url: `/procedures/last-day-reports`,
        title: 'Last Day Reports',
      },
      unknownPatients: {
        url: `/procedures/unknown-procedures`,
        title: 'Unknown Procedures',
      },
      lateActivityEvents: {
        url: `/procedures/late-activity`,
        title: 'Late Activity Procedures',
      },
      lockedProcedures: {
        url: `/procedures/locked`,
        title: 'Locked Procedures',
      },
      monitoring: {
        index: {
          url: `/procedures/:id/monitoring`,
          title: 'Procedure Monitoring',
          showCalendar: true,
          showProcedure: true,
        },
        edit: {
          url: `/procedures/:id/monitoring/edit`,
          title: 'Edit Procedure Monitoring',
          showCalendar: true,
          showProcedure: true,
        },
        split: {
          url: `/procedures/:id/monitoring/split`,
          title: 'Split Procedure',
          showCalendar: true,
          showProcedure: true,
        },
        end: {
          url: `/procedures/:id/monitoring/end`,
          title: 'End Procedure',
          showCalendar: true,
          showProcedure: true,
        },
      },
      triages: {
        escalated: {
          url: `/procedure/:id/escalated-triage`,
          title: 'Procedure Escalated Triage',
          showProcedure: true,
          showCalendar: true,
        },
        common: {
          url: `/procedure/:id/common-triage`,
          title: 'Procedure Common Triage',
          showProcedure: true,
          showCalendar: true,
        },
      },
      reports: {
        create: {
          url: `/procedures/:id/report/create`,
          title: 'Create report',
          showCalendar: true,
          showProcedure: true,
        },
        list: {
          url: `/procedures/:id/report/list`,
          title: 'All reports',
          showCalendar: true,
          showProcedure: true,
        },
      },
    },
    physicians: {
      list: {
        url: `/physicians`,
        title: 'All Physicians',
      },
      create: {
        url: `/physicians/create`,
        title: 'Create Physician',
      },
      edit: {
        url: '/physicians/:id/edit',
        title: 'Edit Physician',
      },
    },
    phones: {
      list: {
        title: 'Phones',
        url: '/phones',
      },
      create: {
        title: 'Create Phone',
        url: '/phones/create',
      },
      edit: {
        title: 'Edit Phone',
        url: '/phones/:id/edit',
      },
    },
    patients: {
      list: {
        url: '/patients',
        title: 'Patients',
      },
      create: {
        url: '/patients/create',
        title: 'Create Patient',
      },
      edit: {
        url: '/patients/:id/edit',
        title: 'Edit Patient',
      },
    },
    triages: {
      escalated: {
        url: `/escalated-triage`,
        title: 'Escalated Triage',
      },
      escalatedList: {
        url: `/escalated-triage-list`,
        title: 'Procedures Escalated Triage',
      },
      common: {
        url: `/common-triage`,
        title: 'Common Triage',
      },
    },
    enroll: {
      url: `/enroll`,
      title: 'Enroll',
    },
    mobileAppResources: {
      url: `/mobile-app-resources`,
      title: 'Mobile App Resources',
    },
    orderForms: {
      new: {
        url: `/new-order-forms`,
        title: 'New Order Forms',
      },
    },
    organizations: {
      list: {
        title: 'All Organizations',
        url: '/organizations',
      },
      create: {
        title: 'Create Organization',
        url: '/organizations/create',
      },
      edit: {
        title: 'Edit Organization',
        url: '/organizations/:id/edit',
      },
    },
    users: {
      list: {
        url: '/users',
        title: 'All Users',
      },
      create: {
        title: 'Create User',
        url: '/users/create',
      },
      edit: {
        title: 'Edit User',
        url: '/users/:id/edit',
      },
    },
    roles: {
      title: 'Roles',
      url: '/roles',
    },
    simCards: {
      list: {
        title: 'Sim Cards',
        url: '/simcards',
      },
      create: {
        title: 'Create Sim Card',
        url: '/simcards/create',
      },
      edit: {
        title: 'Edit Sim Card',
        url: '/simcards/:id/edit',
      },
    },
    patches: {
      list: {
        title: 'Patches',
        url: '/patches',
      },
      create: {
        title: 'Create Patch',
        url: '/patches/create',
      },
      edit: {
        title: 'Edit Patch',
        url: '/patches/:id/edit',
      },
    },
    kits: {
      list: {
        title: 'Kits',
        url: '/kits',
      },
      create: {
        title: 'Create Kit',
        url: '/kits/create',
      },
      edit: {
        title: 'Edit Kit',
        url: '/kits/:id/edit',
      },
    },
    reports: {
      list: {
        title: 'Reports',
        url: '/reports',
      },
      create: {
        title: 'Create',
        url: '/reports/create',
      },
      view: {
        title: 'View Report',
        url: '/reports/:id/view',
      },
    },
  },
  physician: {
    index: {
      url: '/',
      title: 'Recent Procedures',
    },
    procedures: {
      all: {
        url: '/physician/procedures/all',
        title: 'All Procedures',
      },
    },
    orderForms: {
      index: {
        url: '/physician/order-forms',
        title: 'Order Forms',
      },
      create: {
        url: '/physician/order-forms/create',
        title: 'Create Order Forms',
      },
    },
  },
  contact: {
    index: {
      url: '/',
      title: 'Recent Procedures',
    },
    procedures: {
      all: {
        url: '/contact/procedures/all',
        title: 'All Procedures',
      },
    },
  },
};

export const urlEnum = getUrlEnum(stack);
const paths = recursiveKey(stack, 'url');

export const resetPasswordUrl = paths.public.resetPassword.replace(
  ':token',
  ''
);

export default paths;
