import React from 'react';
import { Text } from '@react-pdf/renderer';

import Field from './Field';

const SignAndAgreementSection = ({ title, text, label, value = '  ' }) => (
  <>
    <Text
      style={{
        fontSize: 12,
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Times-Bold',
      }}
    >
      {title || ' '}
    </Text>

    <Text style={{ fontFamily: 'Times-Roman', fontSize: 11, maxWidth: '97%' }}>
      {text || ' '}
    </Text>

    <Field
      value={value}
      label={label}
      labelStyle={{
        maxWidth: '50%',
        fontFamily: 'Times-Bold',
        fontSize: 12,
      }}
      valueStyles={{
        fontFamily: 'Birthstone',
        fontSize: 18,
        letterSpacing: 1,
        borderBottom: '1px solid black',
      }}
    />
  </>
);

export default SignAndAgreementSection;
