export const symptomsOptions = [
  { value: 'R00.1', label: 'R00.1 Bradycardia, Unspecified' },
  { value: 'R55', label: 'R55 Syncope & Collapse' },
  { value: 'R42', label: 'R42 Dizziness' },
  { value: 'R00.0', label: 'R00.0 Tachycardia, Unspecified' },
  { value: 'R00.2', label: 'R00.2 Palpitations' },
  { value: 'I45.9', label: 'I45.9 Conduction Dis.' },
  { value: 'I44.30', label: 'I44.30 Unspecified Atrioventricular Block' },
  { value: 'I45.5', label: 'I45.5 Other specified heart block' },
  { value: 'I45.89', label: 'I45.89 Other conduction disorders' },
  { value: 'I47.1', label: 'I47.1 Supraventricular Tachycardia' },
  { value: 'I47.2', label: 'I47.2 Ventricular Tachycardia' },
  { value: 'I48.91', label: 'I48.91 Atrial Fibrillation, Unspecified' },
  { value: 'I48.92', label: 'I48.92 Atrial Flutter, Unspecified' },
  { value: 'I49.1', label: 'I49.1 Atrial Premature Depolarization' },
  { value: 'I49.5', label: 'I49.5 Sinoatrial Node Dys. / Sick Sinus Syndrome' },
  { value: 'I49.9', label: 'I49.9 Cardiac Arrhythmia, Unspecified' },
];

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export const cardiacTestingOptions = [
  { label: 'MCT + Holter', value: 'mctHolter' },
  { label: 'MCT Only', value: 'mct' },
];
