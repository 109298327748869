import { symptomsOptions } from './options';

export const generalPatientInfo = [
  { name: 'name' },
  {
    name: 'address',
    width: '100%',
  },
  { name: 'city', width: '35%' },
  { name: 'state', width: '35%' },
  { name: 'zip', width: '30%' },
];

export const extendedPatientInfo = [
  { name: 'dob', width: '50%' },
  { name: 'gender', width: '50%', field: 'radio' },
  { name: 'ssnNum' },
  { name: 'phone' },
];

export const symptomsAndICD10Info = [
  {
    field: 'checkbox',
    name: 'symptoms',
    label: 'Symptoms',
    options: symptomsOptions,
    twoCols: true,
  },
  { name: 'notes', label: 'Other/s Not Listed' },
];
