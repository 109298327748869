import { all, fork } from 'redux-saga/effects';

import { commonDucksSaga } from 'common/ducks';
import kits from 'common/modules/kits/ducks/sagas';
import reports from 'common/modules/reports/ducks/sagas';
import presets from 'common/components/Widgets/PresetsTable/ducks/sagas';
import patients from 'common/modules/patients/ducks/sagas';
import contacts from 'common/components/Widgets/contacts/ducks/sagas';
import gridSagas from 'common/modules/EventsGrid/ducks/sagas';
import orderForms from 'common/modules/orderForms/ducks/sagas';
import eventStrip from 'common/modules/eventStrip/ducks/sagas';
import procedures from 'common/modules/procedures/ducks/sagas';
import attachments from 'common/components/Widgets/attachments/ducks/sagas';
import specialNotes from 'common/components/Widgets/specialNotes/ducks/sagas';
import tableFilters from 'common/components/Table/TableFilterSet/ducks/sagas';
import templatesSagas from 'common/modules/EventsGrid/isolatedDucks/sagas';
import reportCalendar from 'common/components/MonitoringCalendar/ducks/sagas';
import escalatedReport from 'common/modules/escalatedReport/ducks/sagas';
import proceduresListWithEvents from 'common/modules/proceduresListWithEvents/ducks/sagas';

import organizationSelector from 'ui/layout/OrganizationSelector/ducks/sagas';

import users from 'ui/modules/users/ducks/sagas';
import phones from 'ui/modules/phones/ducks/sagas';
import patches from 'ui/modules/patches/ducks/sagas';
import profile from 'ui/modules/profile/ducks/sagas';
import simCards from 'ui/modules/simCards/ducks/sagas';
import dashboard from 'ui/modules/dashboard/ducks/sagas';
import procedure from 'ui/modules/procedure/ducks/sagas';
import pdfReport from 'ui/modules/pdfReport/ducks/sagas';
import userRoles from 'ui/modules/organizations/ducks/sagas';
import physicians from 'ui/modules/physicians/ducks/sagas';
import dailyReport from 'ui/modules/dailyReport/ducks/sagas';
import fullDisclosure from 'ui/modules/fullDisclosure/ducks/sagas';
import globalEscalated from 'ui/modules/globalEscalated/ducks/sagas';
import monitoringTriages from 'ui/modules/monitoringTriages/ducks/sagas';
import mobileAppResources from 'ui/modules/mobileAppResources/ducks/sagas';

const sagas = [
  escalatedReport,
  commonDucksSaga,
  procedures,
  globalEscalated,
  proceduresListWithEvents,
  profile,
  eventStrip,
  reportCalendar,
  userRoles,
  procedure,
  specialNotes,
  fullDisclosure,
  physicians,
  attachments,
  patients,
  kits,
  phones,
  monitoringTriages,
  simCards,
  patches,
  tableFilters,
  users,
  presets,
  reports,
  contacts,
  pdfReport,
  gridSagas,
  dailyReport,
  organizationSelector,
  templatesSagas,
  dashboard,
  mobileAppResources,
  orderForms,
];

export default function* rootSaga() {
  yield all(sagas.flat().map((saga) => fork(saga)));
}
