import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useController } from 'react-hook-form';

const PhysicianAssigned = ({ options = [], name, control }) => {
  const { field } = useController({ control, name });

  return (
    <Autocomplete
      multiple
      disabled
      value={field.value}
      options={field.value || options}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Physician Assigned"
          placeholder="Physician Assigned"
        />
      )}
    />
  );
};

export default PhysicianAssigned;
