import PhoneMaskInput from 'common/components/inputMasks/PhoneMaskInput';
import BooleanField from 'common/components/formFields/BooleanField';

import PhysicianAssigned from '../components/PhysicianAssigned';

export default [
  {
    name: 'firstName',
    label: 'First Name',
    width: '49%',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    width: '49%',
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    width: '49%',
  },
  {
    name: 'phone',
    label: 'Phone',
    required: true,
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
    width: '49%',
  },
  {
    name: 'assignedPhysiciansNames',
    label: 'Physician Assigned',
    required: true,
    editOnly: true,
    component: PhysicianAssigned,
    width: '49%',
  },
  {
    name: 'use2FactorAuth',
    label: 'Two Factor Authentication',
    component: BooleanField,
    defaultValue: true,
    width: '49%',
  },
];
