import moment from 'moment';

import PhoneMaskInput, {
  CardMaskInput,
} from 'common/components/inputMasks/PhoneMaskInput';
import Datepicker from 'common/components/formFields/Datepicker';
import BooleanField from 'common/components/formFields/BooleanField';
import RadioGroupField from 'common/components/formFields/RadioGroupField';
import CheckboxGroupField from 'common/components/formFields/CheckboxGroupField';

import {
  genderOptions,
  symptomsOptions,
  cardiacTestingOptions,
} from './options';

export const generalInfo = [
  {
    name: 'hookupDate',
    label: 'Hookup Date:',
    component: Datepicker,
    getRenderValue: (d) => moment(d).format('MM/DD/YYYY'),
  },
  { name: 'duration', label: 'Duration:', type: 'number' },
  { name: 'serialNumber', label: 'Recorder Serial #:', width: '45%' },
  {
    name: 'isShipToPatient',
    label: 'or Ship To Patient:',
    component: BooleanField,
    field: 'radio',
    width: '45%',
  },
];

export const patientInfo = [
  { name: 'name', label: 'Name:', style: { height: 'min-content' } },
  {
    xs: 3,
    name: 'dob',
    label: 'Date of Birth:',
    component: Datepicker,
    getRenderValue: (d) => moment(d).format('MM/DD/YYYY'),
  },
  {
    name: 'gender',
    label: 'Gender:',
    xs: 3,
    row: true,
    field: 'radio',
    options: genderOptions,
    component: RadioGroupField,
  },
  { name: 'address', label: 'Address:' },
  {
    name: 'ssnNum',
    label: 'SSN#',
    InputProps: { inputComponent: CardMaskInput },
  },
  { name: 'city', label: 'City:', xs: 2 },
  { name: 'state', label: 'State:', xs: 2 },
  { name: 'zip', label: 'Zip:', xs: 2 },
  {
    name: 'phone',
    label: 'Telephone#:',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
];

export const insuranceInfo = [
  { name: 'insurance.name', label: 'Name:' },
  {
    name: 'insurance.phone',
    label: 'Insurance Phone:',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
  { name: 'insurance.memberId', label: 'Member ID#:' },
  { name: 'insurance.groupId', label: 'Group ID#:' },
];

export const physicianInfo = [
  { name: 'practiceName', label: 'Practice Name:' },
  { name: 'physicianName', label: 'Physician Name:' },
  {
    name: 'physicianPhone',
    label: 'Physician Phone:',
    InputProps: {
      inputComponent: PhoneMaskInput,
    },
  },
];

export const symptomsAndICD10Info = [
  {
    field: 'checkbox',
    name: 'symptoms',
    label: 'Symptoms',
    component: CheckboxGroupField,
    options: symptomsOptions,
    twoCols: true,
  },
  { name: 'notes', label: 'Other/s Not Listed:' },
  {
    name: 'cardiacTestingSelection',
    label: 'Report Format Selection',
    component: RadioGroupField,
    options: cardiacTestingOptions,
    row: true,
  },
  { name: 'physicianSignature', label: 'Physician Signature', xs: 6 },
  { name: 'patientSignature', label: 'Patient Signature', xs: 6 },
];
