import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-pdf/renderer';

import { typography } from './constants';

const mainFontSize = 12;

export const convertRemToPixels = (rem) => rem * mainFontSize;

const PDFTypography = ({
  styles = {},
  variant = 'caption',
  children = '',
  ...params
}) => {
  const typographyStyles = typography[variant];
  const fontSize = convertRemToPixels(parseFloat(typographyStyles.fontSize));

  return (
    <Text style={{ ...typographyStyles, fontSize, ...styles }} {...params}>
      {children}
    </Text>
  );
};

PDFTypography.propTypes = {
  styles: PropTypes.oneOf(PropTypes.any),
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default PDFTypography;
