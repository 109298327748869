import React, { memo } from 'react';
import {
  Page,
  View,
  Font,
  Image,
  Document,
  PDFViewer,
  StyleSheet,
} from '@react-pdf/renderer';

import BirthstoneFont from 'assets/fonts/Birthstone-Regular.ttf';

import {
  patientInfo,
  physicianInfo,
  insuranceInfo,
} from './constants/dataModels';
import {
  generalPatientInfo,
  extendedPatientInfo,
  symptomsAndICD10Info,
} from './constants/renderModels';
import Title from './components/Title';
import Section from './components/Section';
import GeneralInfo from './components/GeneralInfo';
import InfoContainers from './components/InfoContainers';
import SignAndAgreementSection from './components/SignAndAgreementSection';
import CardiacTestingSelection from './components/CardiacTestingSelection';

const styles = StyleSheet.create({
  sectionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 4,
  },
  smallSection: {
    width: '50%',
  },
});

const pdfPageProps = {
  size: 'LETTER',
  style: { width: '100%', padding: 12 },
};

Font.register({
  family: 'Birthstone',
  src: BirthstoneFont,
});

const PDFRenderer = ({ onPdfRender, data }) => {
  return (
    <PDFViewer
      width="100%"
      height="100%"
      style={{ zIndex: 10, fontFamily: 'Roboto' }}
    >
      <Document title="Order Form" onRender={onPdfRender}>
        <Page
          {...pdfPageProps}
          style={{ width: '100%', padding: 10, paddingTop: 14 }}
        >
          <View style={styles.sectionWrapper}>
            <Title style={styles.smallSection} />

            <Section
              highlight={false}
              style={{ ...styles.smallSection, marginLeft: 8 }}
            >
              <GeneralInfo data={data} />
            </Section>
          </View>

          <View style={styles.sectionWrapper}>
            <Section
              highlight={false}
              style={{ ...styles.smallSection, marginRight: 4 }}
            >
              <InfoContainers
                data={data}
                model={generalPatientInfo}
                originalModel={patientInfo}
              />
            </Section>

            <Section
              highlight={false}
              style={{ ...styles.smallSection, marginLeft: 4 }}
            >
              <InfoContainers
                data={data}
                model={extendedPatientInfo}
                originalModel={patientInfo}
              />
            </Section>
          </View>

          <View style={styles.sectionWrapper}>
            <Section
              highlightTitle="Insurance Info"
              style={{ ...styles.smallSection, marginRight: 4 }}
            >
              <InfoContainers data={data} model={insuranceInfo} />
            </Section>

            <Section
              highlightTitle="Physician Info"
              style={{ ...styles.smallSection, marginLeft: 4 }}
            >
              <InfoContainers
                data={data}
                model={physicianInfo}
                style={{
                  flex: 1,
                  alignContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              />
            </Section>
          </View>

          <Section
            style={{
              ...styles.sectionWrapper,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            highlightTitle="MUST Complete Symptoms and ICD10 Codes For Processing"
            highlightPosition="top"
          >
            <InfoContainers
              data={data}
              model={symptomsAndICD10Info}
              originalModel={symptomsAndICD10Info}
              style={{ width: '90%' }}
            />
          </Section>

          <Section
            style={styles.sectionWrapper}
            highlightTitle="Cardiac Testing Selection (48-hr Minimum)"
            highlightSubtitle="*Please Update Your Billing Dept for CPT Coding"
            highlightPosition="top"
          >
            <CardiacTestingSelection data={data} />
          </Section>

          <Section style={styles.sectionWrapper}>
            <SignAndAgreementSection
              value={data.physicianSignature}
              text="This cardiac testing is being prescribed to assist in identifying patients' possible transient arrhythmias related to their symptoms or diagnosis as indicated above and is not for life-threatening diagnostic prescription. Consent is also being given to receive fax and other electronic messages at the numbers and addresses provided to Versa Cardio."
              label="Physician or Medical Professional Signature:"
              title="Statement of Medical Necessity, Order & Communications Consent"
            />
          </Section>

          <Section style={styles.sectionWrapper}>
            <SignAndAgreementSection
              title="Statement of Medical Necessity"
              text="I hereby authorize Versa Cardio to contact me via fax, phone, and text messaging, as needed, at the numbers and addresses provided. I also authorize them to inquire, appeal, and receive all information on my behalf regarding the procedure(s) on this order."
              label="Patient or Responsible Party Signature:"
              value={data.patientSignature}
            />
          </Section>
        </Page>

        {(data.attachments || []).map((a) => {
          if (a.ext === 'pdf') {
            return (
              <Page break key={`pdfD-${a.id}`} {...pdfPageProps}>
                {a.pdfData.map((d) => (
                  <Image key={d.id} src={d.image} title={a.name} />
                ))}
              </Page>
            );
          }

          return (
            <Page break key={a.id} {...pdfPageProps}>
              <Image src={URL.createObjectURL(a.file)} />
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
};

export default memo(PDFRenderer, (prev, next) => prev.data === next.data);
