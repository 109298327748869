import moment from 'moment';
import { dateFormat } from 'common/constants/dateFormats';

import UpdatePasswordButton from '../components/UpdatePasswordButton';

const getDate = (params) => {
  const { value: date } = params;

  return date ? moment(date).format(dateFormat) : null;
};

const getNameFieldValue = (params) => {
  const { value, row } = params;
  return value || row.original.fullName;
};

export default [
  {
    accessor: 'firstName',
    Header: 'First Name',
    width: 15,
    valueGetter: getNameFieldValue,
  },
  {
    accessor: 'lastName',
    Header: 'Last Name',
    width: 15,
    valueGetter: getNameFieldValue,
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    width: 15,
  },
  {
    accessor: 'email',
    Header: 'Email',
    width: 15,
  },
  {
    accessor: 'createdAt',
    Header: 'Added',
    width: 10,
    valueGetter: getDate,
  },
  {
    accessor: 'updPassBtn',
    Header: 'Change Password',
    width: 10,
    Cell: UpdatePasswordButton,
    notClickable: true,
  },
];
