import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import PDFTypography from 'common/components/PDFTypography';

const styles = StyleSheet.create({
  container: {
    paddingTop: 2,
    paddingLeft: 8,
  },
  bigText: { textAlign: 'center', fontSize: 19, color: '#737373' },
  captionText: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 4,
    color: '#626769',
  },
});

const Title = ({ style = {} }) => (
  <View style={{ ...styles.container, ...style }}>
    <PDFTypography variant="h2" styles={styles.bigText}>
      Procedure Order Form
    </PDFTypography>

    <PDFTypography variant="h2" styles={styles.bigText}>
      Ambulatory Cardiac Telemetry
    </PDFTypography>

    <PDFTypography variant="h4" styles={styles.captionText}>
      *Not for life-threatening diagnostic prescription
    </PDFTypography>
  </View>
);

export default Title;
