import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

import PDFTypography from 'common/components/PDFTypography';

const getStyles = (type = 'text') => {
  const isCheckBox = type === 'checkbox';

  const isText = type === 'text';

  return StyleSheet.create({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: isCheckBox ? 'row-reverse' : 'row',
      alignItems: isText ? 'flex-end' : 'center',
      justifyContent: isCheckBox ? 'flex-start' : 'space-between',
      ...(isCheckBox ? { alignItems: 'flex-start' } : {}),
    },
    label: {
      flexGrow: 1,
      maxWidth: isText ? '50%' : '90%',
      ...(isCheckBox ? { fontSize: 10 } : {}),
      paddingBottom: isText ? 1 : 0,
      marginRight: isText ? 2 : 0,
      fontSize: 11,
      fontFamily: 'Times-Roman',
    },
    valueContainer: {
      flexGrow: isText ? 1 : 0,
      alignItems: !isText && 'flex-end',
      paddingRight: isCheckBox ? 8 : 0,
    },
    text: {
      fontSize: 10,
      paddingLeft: isText ? 6 : 0,
      fontWeight: 700,
      borderBottom: '1px solid #bdc0c0',
    },

    radio: {
      border: '1px solid black',
      width: 12,
      height: 12,
      padding: 1,
      borderRadius: '50%',
    },
    radioChecked: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      backgroundColor: '#dadffe',
    },

    checkbox: {
      width: 12,
      height: 12,
      padding: 1,
      border: '1px solid black',
    },
    checkboxChecked: {
      width: '100%',
      height: '100%',
      backgroundColor: '#dadffe',
    },
  });
};

const Field = ({
  type = 'text',
  style = {},
  label,
  value,
  labelStyle = {},
  valueStyles = {},
  ...props
}) => {
  const styles = getStyles(type);
  const isCheckBox = type === 'checkbox';

  return (
    <View style={{ ...styles.container, ...style }} {...props}>
      {isCheckBox ? (
        <PDFTypography variant="h3" styles={{ ...styles.label, ...labelStyle }}>
          {label}
        </PDFTypography>
      ) : (
        <View style={{ width: 'auto' }}>
          <PDFTypography
            variant="h3"
            styles={{ ...styles.label, ...labelStyle }}
          >
            {label}
          </PDFTypography>
        </View>
      )}

      <View style={styles.valueContainer}>
        {
          {
            text: (
              <PDFTypography
                variant="h4"
                styles={{ ...styles.text, ...valueStyles }}
              >
                {value}
              </PDFTypography>
            ),
            radio: (
              <View style={{ ...styles.radio, ...valueStyles }}>
                {!!value && <View style={styles.radioChecked} />}
              </View>
            ),
            checkbox: (
              <View style={{ ...styles.checkbox, ...valueStyles }}>
                {!!value && <View style={styles.checkboxChecked} />}
              </View>
            ),
          }[type]
        }
      </View>
    </View>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelStyle: PropTypes.objectOf(PropTypes.any),
  valueStyles: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.oneOf(['text', 'radio', 'checkbox']),
};

export default Field;
