import unionBy from 'lodash/unionBy';

import { requestReducer } from 'common/utils/generators';
import { createRequestActionTypes } from 'common/utils/actionTypesHelper';

export const orderFormActionTypes = createRequestActionTypes(
  ['getOne', 'getList', 'create', 'complete'],
  'orderForms'
);

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const orderFormsReducer = requestReducer(
  [orderFormActionTypes.getList],
  initialState,
  (payload, state) => {
    const { data, total } = payload;
    return {
      ...state,
      loading: false,
      data: Array.isArray(data) ? data : unionBy([data], state.data, 'id'),
      total,
    };
  }
);

export const getList = (payload) => ({
  type: orderFormActionTypes.getList.requested,
  payload,
});

export const getOne = (payload) => ({
  type: orderFormActionTypes.getOne.requested,
  payload,
});

export const create = (payload, onSuccess, onError) => ({
  type: orderFormActionTypes.create.requested,
  payload,
  onSuccess,
  onError,
});

export const complete = (payload, onSuccess, onError) => ({
  type: orderFormActionTypes.complete.requested,
  payload,
  onSuccess,
  onError,
});

export default orderFormsReducer;
