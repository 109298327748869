import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { createEnum } from 'common/utils/actionTypesHelper';

const booleanInputTypes = createEnum({
  checkbox: null,
  switch: null,
  radio: null,
});

const booleanInputs = {
  [booleanInputTypes.checkbox]: Checkbox,
  [booleanInputTypes.switch]: Switch,
  [booleanInputTypes.radio]: Radio,
};

const BooleanInput = ({
  name,
  label = '',
  inputProps = {},
  size = 'small',
  color = 'primary',
  onChange = () => null,
  value = false,
  type = booleanInputTypes.switch,
  classes,
  className = '',
  ...props
}) => {
  const BooleanInputComponent = booleanInputs[type] || Switch;

  const component = useMemo(
    () => (
      <BooleanInputComponent
        {...props}
        size={size}
        name={name}
        color={color}
        checked={value}
        classes={classes}
        onChange={onChange}
        inputProps={{ 'aria-label': `${color} checkbox`, ...inputProps }}
      />
    ),
    [classes, color, inputProps, name, onChange, props, size, value]
  );

  return label ? (
    <FormControlLabel
      label={label}
      classes={classes}
      control={component}
      className={className}
    />
  ) : (
    component
  );
};

BooleanInput.propTypes = {
  value: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.shape(),
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  field: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
};

export default BooleanInput;
