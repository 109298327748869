import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import Field from './Field';
import { cardiacTestingOptions } from '../constants/options';

const styles = StyleSheet.create({
  title: {
    fontSize: 11,
    fontFamily: 'Times-Bold',
    paddingBottom: 2,
  },
  field: {
    alignItems: 'center',
  },
  section: {
    width: '33%',
  },
  text: {
    fontSize: 11,
    fontFamily: 'Times-Roman',
  },
});

const [mctHolter, mct] = cardiacTestingOptions;

const CardiacTestingSelection = ({ data }) => (
  <>
    <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
      <View style={styles.section}>
        <Text style={styles.title}>MCT + HOLTER:</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>MCT Only:</Text>
      </View>
    </View>

    <View
      style={{
        paddingBottom: 4,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
      }}
    >
      <View style={styles.section}>
        <Field
          value={data.cardiacTestingSelection === mctHolter.value}
          label="Holter, CPT 93225 and 93227 with Mobile Cardiac Telemetry, CPT 93228"
          type="checkbox"
          style={styles.field}
        />
      </View>

      <View style={styles.section}>
        <Field
          value={data.cardiacTestingSelection === mct.value}
          label=" Mobile Cardiac Telemetry, CPT 93228"
          type="checkbox"
          style={styles.field}
        />
      </View>
    </View>

    <Text style={styles.text}>
      MCT + Holter testing option: Physician shall review results from the
      Holter test to determine continued MCT testing. If MCT is no longer
      required, the physician shall request MCT termination within 24 hours of
      initial patient hookup.
    </Text>

    <Text style={{ ...styles.text, paddingTop: 4 }}>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      If the patient's insurance carrier does not reimburse for mobile cardiac
      telemetry testing, please accept this as my written order for a cardiac
      event monitoring (93270 & 93272) test procedure. If left blank, the study
      will default to either mobile cardiac telemetry (93228) or cardiac event
      monitoring (93270 & 93272) for up to 30 days.
    </Text>
  </>
);

export default CardiacTestingSelection;
