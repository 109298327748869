import { takeLatest, put, call } from 'redux-saga/effects';

import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import { requestSaga } from 'common/utils/generators';
import { orderFormActionTypes } from './reducer';

function* getListSaga() {
  yield requestSaga({
    actionType: orderFormActionTypes.getList,
    request: dataProvider.getList,
    resource: resources.orderForm.list,
  });
}

function* updateOrderFormSaga() {
  yield requestSaga({
    actionType: orderFormActionTypes.complete,
    request: dataProvider.updateOne,
    resource: resources.orderForm.index,
  });
}

function* createFormOrder({ payload, onSuccess, onError }) {
  try {
    const { file, patientData } = payload;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const { data } = yield call(
      dataProvider.createOneFormData,
      resources.files.upload,
      { body: formData }
    );

    const res = yield call(dataProvider.createOne, resources.orderForm.index, {
      body: { ...patientData, fileId: data.id },
    });

    onSuccess?.();

    yield put({
      type: orderFormActionTypes.create.success,
      data: res.data,
    });
  } catch (error) {
    onError?.();
    yield put({ type: orderFormActionTypes.create.failed, payload: error });
  }
}

function* createFormOrderSaga() {
  yield takeLatest(orderFormActionTypes.create.requested, createFormOrder);
}

const sagas = [getListSaga, updateOrderFormSaga, createFormOrderSaga];

export default sagas;
