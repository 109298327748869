import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

// COMMON REDUCERS
import commonReducers from 'common/ducks';
import kits from 'common/modules/kits/ducks';
import presets from 'common/components/Widgets/PresetsTable/ducks';
import patients from 'common/modules/patients/ducks';
import contacts from 'common/components/Widgets/contacts/ducks';
import procedures from 'common/modules/procedures/ducks';
import orderForms from 'common/modules/orderForms/ducks';
import reportTable from 'common/modules/reports/ducks';
import gridReducer from 'common/modules/EventsGrid/ducks';
import undoReducer from 'common/modules/Undo/ducks';
import attachments from 'common/components/Widgets/attachments/ducks';
import notification from 'common/modules/Notification/ducks';
import tableFilters from 'common/components/Table/TableFilterSet/ducks';
import specialNotes from 'common/components/Widgets/specialNotes/ducks';
import reportCalendar from 'common/components/MonitoringCalendar/ducks';
import escalatedReport from 'common/modules/escalatedReport/ducks';
import localGrid from 'common/modules/EventsGrid/isolatedDucks';
import eventTriageStrips from 'common/modules/eventStrip/ducks';
import proceduresListWithEvents from 'common/modules/proceduresListWithEvents/ducks';

// LAYOUT REDUCERS
import modals from 'ui/layout/ModalHandler/ducks';
import organizationSelector from 'ui/layout/OrganizationSelector/ducks';
// MODULES REDUCERS
import users from 'ui/modules/users/ducks';
import phones from 'ui/modules/phones/ducks';
import patches from 'ui/modules/patches/ducks';
import profile from 'ui/modules/profile/ducks';
import simCards from 'ui/modules/simCards/ducks';
import pdfReport from 'ui/modules/pdfReport/ducks';
import dashboard from 'ui/modules/dashboard/ducks';
import procedure from 'ui/modules/procedure/ducks';
import physicians from 'ui/modules/physicians/ducks';
import onGoingReport from 'ui/modules/dailyReport/ducks';
import organizations from 'ui/modules/organizations/ducks';
import fullDisclosure from 'ui/modules/fullDisclosure/ducks';
import globalEscalated from 'ui/modules/globalEscalated/ducks';
import monitoringTriages from 'ui/modules/monitoringTriages/ducks';
import mobileAppResources from 'ui/modules/mobileAppResources/ducks';

import entities from './reducers/entities';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  undo: undoReducer,
  ...commonReducers,
  router: connectRouter(history),
  dashboard,
  entities,
  gridItems: gridReducer,
  localGrid,
  procedures,
  proceduresListWithEvents,
  globalEscalated,
  reportCalendar,
  organizationSelector,
  profile,
  organizations,
  notification,
  modals,
  ...eventTriageStrips,
  procedure,
  specialNotes,
  fullDisclosure,
  physicians,
  attachments,
  patients,
  kits,
  phones,
  monitoringTriages,
  simCards,
  patches,
  tableFilters,
  users,
  presets,
  reportTable,
  escalatedReport,
  onGoingReport,
  contacts,
  pdfReport,
  mobileAppResources,
  orderForms,
});

export default rootReducer;
