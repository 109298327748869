import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import makeStyles from '@material-ui/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useController } from 'react-hook-form';

const sanitizeProps = ({ onInputChange, fullWidth, ...props }) => props;

const useStyles = makeStyles(({ spacing }) => ({
  root: { paddingLeft: spacing(1) },
}));

const CheckboxGroupField = ({
  row,
  name,
  form,
  rules,
  label,
  control,
  twoCols = false,
  options = [],
  fullWidth,
  defaultValue = {},
  ...rest
}) => {
  const classes = useStyles();
  const props = sanitizeProps(rest);
  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const mid = options.length / 2;

  const onChange = (e) => {
    field.onChange({ ...field.value, [e.target.name]: e.target.checked });
  };

  const renderOption = useCallback(
    (option) => (
      <FormControlLabel
        key={option.label}
        control={
          <Checkbox
            name={option.value}
            checked={!!field.value?.[option.value]}
            color="primary"
          />
        }
        label={option.label}
        value={option.value}
        style={{ width: '100%' }}
      />
    ),
    [field.value]
  );

  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <FormGroup
        aria-label="gender"
        name="gender1"
        value={field.value}
        onChange={onChange}
        {...rest}
        {...props}
        classes={classes}
        style={{
          width: '100%',
          display: 'flex',
        }}
      >
        {twoCols ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {options.slice(0, Math.ceil(mid)).map(renderOption)}
            </Grid>

            <Grid item xs={6}>
              {options.slice(Math.floor(mid)).map(renderOption)}
            </Grid>
          </Grid>
        ) : (
          options.map(renderOption)
        )}
      </FormGroup>
    </FormControl>
  );
};

CheckboxGroupField.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.objectOf(PropTypes.any),
  form: PropTypes.shape({
    errors: PropTypes.objectOf(PropTypes.any),
    touched: PropTypes.objectOf(PropTypes.any),
  }),
  twoCols: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.objectOf(PropTypes.any),
};

export default CheckboxGroupField;
