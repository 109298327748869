const getNameFieldValue = (params) => {
  const { value, row } = params;
  return value || row.original.fullName;
};

export const selectColumns = [
  {
    accessor: 'firstName',
    Header: 'First Name',
    width: 18,
    valueGetter: getNameFieldValue,
  },
  {
    accessor: 'lastName',
    Header: 'Last Name',
    width: 18,
    valueGetter: getNameFieldValue,
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    width: 12,
  },
  {
    accessor: 'email',
    Header: 'Email',
    width: 25,
  },
  {
    accessor: 'assignedPhysiciansNames',
    Header: 'Physician Assigned',
    width: 27,
    valueGetter: ({ value }) => [value].flat().filter(Boolean).join(', '),
  },
];
