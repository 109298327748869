import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { v4 as uuidv4 } from 'uuid';

import { mimeTypes } from 'common/constants/enums';
import TextInput from 'common/components/simpleInputs/TextInput';
import Button from 'common/components/buttons/ModalActionButton';

import useStyles from '../styles';
import { getExtension } from '../utils';

const FileSelectionModal = ({
  allowedFormats,
  maxFileSize,
  fileTypes,
  saveFiles,
  onClose,
  data = [],
}) => {
  const classes = useStyles();
  const fileInput = useRef(null);
  const [fileType, setFileType] = useState(fileTypes[0].value);
  const [files, setFiles] = useState(data);

  const acceptMimeTypes = allowedFormats.map(
    (format) => mimeTypes[format.toLowerCase()]
  );

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const removeFile = (id) => {
    fileInput.current.value = null;
    setFiles((prevFiles) => prevFiles.filter((f) => f.id !== id));
  };

  const onFileTypeChange = (_, value) => {
    setFileType(value);
  };

  const onFileSelect = (e) => {
    const [file] = e.target.files;

    if (file) {
      const ext = file.name && getExtension(file.name);

      const fileData = {
        ext,
        file,
        fileType,
        id: uuidv4(),
        name: file.name,
      };

      setFiles((prevFiles) => [...prevFiles, fileData]);
    }
  };

  const chooseFile = () => {
    fileInput.current.click();
  };

  const selectFiles = () => {
    saveFiles?.(files);

    onClose?.();
  };

  return (
    <Grid container direction="column">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item component={Typography} variant="h3" color="primary">
            Files
          </Grid>

          <Grid item>
            <CloseIcon onClick={close} />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {allowedFormats.length > 0 && (
            <Grid item component={Typography}>
              Possible formats: {allowedFormats.join(', ').toUpperCase()}
            </Grid>
          )}

          {maxFileSize && (
            <Grid item component={Typography}>
              Max File Size: {maxFileSize}MB
            </Grid>
          )}
        </Grid>

        {files.length > 0 && (
          <Grid container direction="column" className={classes.filesWrapper}>
            {files.map((file) => (
              <div key={file.id} className={classes.uploadWrapper}>
                <Typography>{file.name}</Typography>

                <IconButton onClick={() => removeFile(file.id)}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </Grid>
        )}

        <TextInput
          select
          fullWidth
          name="file"
          size="small"
          value={fileType}
          options={fileTypes}
          onChange={onFileTypeChange}
        />
      </DialogContent>

      <DialogActions style={{ justifyContent: 'space-between' }}>
        <input
          hidden
          type="file"
          ref={fileInput}
          onChange={onFileSelect}
          accept={acceptMimeTypes.join(',')}
        />
        <Button color="primary" onClick={chooseFile}>
          SELECT A FILE
        </Button>

        <Button color="primary" onClick={selectFiles}>
          DONE
        </Button>
      </DialogActions>
    </Grid>
  );
};

FileSelectionModal.defaultProps = {
  allowedFormats: [
    'doc',
    'docx',
    'pdf',
    'xls',
    'xlsx',
    'txt',
    'jpg',
    'jpeg',
    'png',
  ],
  maxFileSize: 20,
};

FileSelectionModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onClose: PropTypes.func,
  saveFiles: PropTypes.func,
  fileTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  maxFileSize: PropTypes.number,
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
};

export default FileSelectionModal;
