import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';

import paths from 'common/constants/path';
import useNotify from 'common/modules/Notification/hooks/useNotify';
import useRedirect from 'common/hooks/useRedirect';
import TriageButton from 'common/components/buttons/TriageButton';
import LoadingProgress from 'common/components/LoadingProgress';
import ModalActionButton from 'common/components/buttons/ModalActionButton';
import { downloadLocalFile } from 'common/utils/providerHelpers/fetchUtils';

import PDFPreview from './PDFRenderer';
import { create } from '../orderForms/ducks/reducer';

const OrderFormPreview = ({ onClose, data }) => {
  const notify = useNotify();
  const redirect = useRedirect('push');
  const dispatch = useDispatch();
  const [pdfUrl, setPdfUrl] = useState();
  const [pdfBlob, setPdfBlob] = useState();
  const [loading, setLoading] = useState(false);

  const onPdfRender = ({ blob }) => {
    setPdfUrl(URL.createObjectURL(blob));
    setPdfBlob(blob);
  };

  const name = `Order_${String(data?.name || '').replace(' ', '_')}`;

  const handleDownload = () => {
    downloadLocalFile(pdfUrl, name);
  };

  const onSuccessCreate = async () => {
    onClose();
    setLoading(false);
    await redirect(paths.physician.orderForms.index);
    notify.success('The Order created successfully!');
  };

  const onErrorCreate = async (e) => {
    setLoading(false);
    notify.error(`The Order Not created: ${e.message || 'Unknown Error!'}`);
  };

  const handleCreate = () => {
    setLoading(true);
    const file = new File([pdfBlob], `${name}.pdf`);

    const [patientFirstName, ...restOfPatientName] = data.name.split(' ');

    const patientData = {
      patientFirstName,
      patientLastName: restOfPatientName.join(' ') || patientFirstName,
      patientBirthDate: new Date(data.dob || '').toISOString(),
      hookupDate: new Date(data.hookupDate || '').toISOString(),
      duration: data.duration,
      patientName: data.name,
      patientPhone: data.phone,
      fileId: null,
    };

    dispatch(create({ file, patientData }, onSuccessCreate, onErrorCreate));
  };

  return (
    <Box display="flex" flexDirection="column" height="100svh">
      <Box
        p={1.5}
        display="flex"
        bgcolor="#f4f4f4"
        position="relative"
        justifyContent="space-between"
      >
        <Box display="flex" sx={{ gap: '16px' }}>
          <TriageButton
            color="default"
            onClick={handleCreate}
            disabled={loading}
          >
            Complete New Order
          </TriageButton>

          <TriageButton color="warning" onClick={handleDownload}>
            Download
          </TriageButton>
        </Box>

        <ModalActionButton onClick={onClose} disabled={loading}>
          Close
        </ModalActionButton>

        {loading && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              height: 4,
              zIndex: 10,
            }}
          >
            <LoadingProgress type="escalated" />
          </Box>
        )}
      </Box>

      <PDFPreview onPdfRender={onPdfRender} data={data} />
    </Box>
  );
};

export default OrderFormPreview;
