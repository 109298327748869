import makeStyles from '@material-ui/styles/makeStyles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const triageButtonColors = {
  monitoringEscalated: {
    bg: '#C6D8EF',
    hoveredBg: '#395691',
  },
  primary: {
    bg: '#5C7AB7',
    hoveredBg: '#395691',
  },
  secondary: {
    bg: '#F05657',
    hoveredBg: '#c24445',
  },
  default: {
    bg: '#569A4D',
    hoveredBg: '#407339',
  },
  warning: {
    bg: '#DE8746',
    hoveredBg: '#BD6D31',
  },
};

export default makeStyles((theme) => ({
  triageButton: ({ color, selected }) => ({
    backgroundColor: triageButtonColors[color].bg,
    borderRadius: 4,
    padding: theme.spacing(1, 2.2),
    textTransform: 'none',
    opacity: selected ? 0.6 : 1,

    '&:hover': {
      backgroundColor: triageButtonColors[color].hoveredBg,
    },

    '&:disabled': {
      backgroundColor: alpha(theme.palette.background.dark, 0.12),
    },
  }),
  label: ({ isDisabled }) => ({
    lineHeight: 'inherit',
    color: isDisabled
      ? alpha(theme.palette.text.primary, 0.3)
      : theme.palette.text.light,
  }),
}));
