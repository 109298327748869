export default {
  account: {
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
  },
  addressStreet: {
    required: 'Street address is required',
  },
  addressCity: {
    required: 'City is required',
  },
  addressState: {
    required: 'State is required',
    length: 'State should be 2 characters long',
  },
  addressZip: {
    required: 'Zip code is required',
    length: 'Zip should be 5 digits long',
    contentType: 'Zip should contain only digits',
  },
  phone: {
    matching: 'Invalid phone number',
    required: 'Phone number is required',
  },
  fax: {
    matching: 'Invalid fax number',
    required: 'Fax number is required',
  },
  phoneAlt: {
    matching: 'Invalid alt. phone number',
  },
  phoneCell: {
    matching: 'Invalid mobile phone number',
  },
  email: {
    email: 'Email is not valid',
    required: 'Email is required',
  },
  gender: {
    required: 'Gender is required',
  },
  hookupType: {
    required: 'Hookup type is required',
  },
  procedureEndDate: {
    test: 'Procedure cannot be longer than 30 days',
  },
  date: {
    invalid: 'Invalid date format',
  },
  patch: {
    required: 'Monitor is required',
  },
  patient: {
    required: 'Patient is required',
  },
  physician: {
    required: 'Physician is required',
  },
  startDate: {
    required: 'Start date is required',
  },
  endDate: {
    min: 'End day should be older or same than start',
    max: 'Duration should be less than 31 day',
  },
  birthDate: {
    required: 'DOB is required',
    test: 'DOB day should be later than today',
  },
  file: {
    large: 'File is too large',
    required: 'File is required',
  },
  organizationName: {
    required: 'Organization name is required',
  },
  status: {
    required: 'Status is required',
  },
  contact: {
    required: 'Contact is required',
  },
  contactName: {
    required: 'Contact name is required',
  },
  physicianName: {
    required: 'Physician name is required',
  },
  patientName: {
    firstNameRequired: 'First name is required',
    lastNameRequired: 'Last name is required',
  },
  iccid: {
    required: 'ICCID is required',
  },
  serialNumber: {
    required: 'Serial number is required',
  },
  phoneIMEI: {
    required: 'Phone IMEI is required',
  },
  btMacAddress: {
    required: 'BT Mac Address is required',
  },
  patchSerial: {
    required: 'Serial patch number is required',
  },
  phoneSerial: {
    required: 'Phone serial number is required',
  },
  simSerial: {
    required: 'SIM serial number is required',
  },
  assignedTo: {
    required: 'Assigned to is required',
  },
  role: {
    required: 'User roles is required',
  },
  version: {
    required: 'Firmware version is required',
  },
  description: {
    required: 'Firmware description is required',
  },
};
